<template>
  <div>
    <public />
    <related-persons />
    <activities />
    <social-media />
    <billing-information />
  </div>
</template>

<script>
import Public from '@/views/Admin/Customers/CustomerForm/Public.vue'
import RelatedPersons from '@/views/Admin/Customers/CustomerForm/RelatedPersons.vue'
import Activities from '@/views/Admin/Customers/CustomerForm/Activities.vue'
import SocialMedia from '@/views/Admin/Customers/CustomerForm/SocialMedia.vue'
import BillingInformation from '@/views/Admin/Customers/CustomerForm/BillingInformation.vue'

export default {
  name: 'CustomerForm',
  components: {
    Public,
    RelatedPersons,
    Activities,
    SocialMedia,
    BillingInformation,
  },
}
</script>
