<template>
  <b-card title="Faaliyet Alanları">
    <sectors />
    <activity />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Sectors from '@/views/Admin/Customers/elements/Activities/Sectors.vue'
import Activity from '@/views/Admin/Customers/elements/Activities/Activity.vue'

export default {
  name: 'Activities',
  components: {
    BCard,
    Sectors,
    Activity,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/dataItem']
    },
  },
}
</script>
