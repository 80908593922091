<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between">
      <div>Genel Bilgi</div>
      <div>
        <b-form-checkbox
          :checked="detailForm"
          name="check-button"
          switch
          inline
          @change="detailToggle"
        >
          Detaylı Bilgi
        </b-form-checkbox>
      </div>
    </b-card-title>
    <b-row>
      <b-col cols="12">
        <customer-type />
      </b-col>
      <b-col cols="12">
        <company />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <phone />
      </b-col>
      <b-col cols="12">
        <phone-alert />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
        md="6"
      >
        <phone2 />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
        md="6"
      >
        <fax />
      </b-col>
      <b-col cols="12">
        <email />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <meet />
      </b-col>
      <b-col
        v-if="detailForm"
        cols="12"
      >
        <notes />
      </b-col>
      <b-col cols="12">
        <user-agent />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import CustomerType from '@/views/Admin/Customers/elements/CustomerType.vue'
import Company from '@/views/Admin/Customers/elements/Company.vue'
import Phone from '@/views/Admin/Customers/elements/Phone.vue'
import PhoneAlert from '@/views/Admin/Customers/elements/PhoneAlert.vue'
import Phone2 from '@/views/Admin/Customers/elements/Phone2.vue'
import Fax from '@/views/Admin/Customers/elements/Fax.vue'
import Email from '@/views/Admin/Customers/elements/Email.vue'
import Meet from '@/views/Admin/Customers/elements/Meet.vue'
import Notes from '@/views/Admin/Customers/elements/Notes.vue'
import UserAgent from '@/views/Admin/Customers/elements/UserAgent.vue'

export default {
  name: 'CustomerForm',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BFormCheckbox,
    CustomerType,
    Company,
    Phone,
    PhoneAlert,
    Phone2,
    Fax,
    Email,
    Meet,
    Notes,
    UserAgent,
  },
  computed: {
    detailForm() {
      return this.$store.getters['customers/detailForm']
    },
  },
  methods: {
    detailToggle() {
      this.$store.commit('customers/SET_DETAIL_FORM', !this.detailForm)
    },
  },
}
</script>
